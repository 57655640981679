import { createI18n } from 'vue-i18n'
import pl from '@/locale/pl.json'
import en from '@/locale/en.json'

const messages = {
    'en': en,
    'pl': pl

};

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

export const locale = 'pl';

export default i18n;