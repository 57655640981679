<template>
	
	<BackgroundPage backgroundColor="var(--lastAbout)"/>
	<PageHeader pageName="cooperation" pageColor="var(--lastAbout)"></PageHeader>

	<main class="text-start p-cooperation m-md-auto">
		<div class="container m-auto">
			<p class="cooperation-text" v-html="$t('cooperation.content')"></p>
		</div>
	</main>

</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import BackgroundPage from "@/components/BackgroundPage.vue";

	export default {
		components: {
			PageHeader, BackgroundPage
		}
	}
</script>

<style>
	.cooperation-bg-color {
		background-color: black !important;
		background: linear-gradient(171deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, var(--lastCooperation) 100%);
		background-repeat: repeat;
		background-size: cover;
		background-attachment: fixed;
		z-index: -9999;
	}
	
	.cooperation-bg-color-div {
	
		background: linear-gradient(171deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, var(--lastCooperation) 100%);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.p-cooperation {
		padding: 4rem;
		margin-top: 6rem;;
	}

	.cooperation-text {
		letter-spacing: 1px;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.875em;
		color: #FFFFFF;
		animation-name: show-cooperation;
		animation-duration: 2s;
		animation-timing-function: ease-out;
	}


	@keyframes show-cooperation {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@media only screen and (max-width: 767px) {
		.p-cooperation {
			padding: 1.5rem;
		}
	}

	@media only screen and (max-width: 990px) {
		.cooperation-text {
			font-size: 14px;
		}
	}
</style>
