<template>
	<div class="bg-color-div w-100" :style="{'--backgroundColor': backgroundColor}"></div>
</template>

<script>
	export default {
		props: ['backgroundColor']
	}
</script>

<style>
	
	.bg-color-div {
	
		background: linear-gradient(160deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, var(--backgroundColor) 100%);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100vh;
		position: fixed;
		top: 10;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	@media only screen and (max-width: 576px) {
		.bg-color-div {
			background-image: linear-gradient(138deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), var(--backgroundColor));
		}
	}

</style>
