<template>
	<PageHeader pageName="lyrics"></PageHeader>
	<div id="lyrics" class="m-auto w-100 ">
		<!-- <div class="row row-cols-1 row-cols-md-4 g-4">
			<div class="col">
				<div class="card h-100  bg-black text-white-50">
					<svg class="bd-placeholder-img card-img-top" width="100%" height="180"
						xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap"
						preserveAspectRatio="xMidYMid slice" focusable="false">
						<title>Placeholder</title>
						<rect width="100%" height="100%" fill="#9771F7"></rect><text x="50%" y="50%" fill="#dee2e6"
							dy=".3em">Image cap</text>
					</svg>

					<div class="card-body">
						<h5 class="card-title">One Little Thing</h5>
						<p class="card-text">WHEN LIFE JUST SEEMS TO BRING YOU DOWN<br />
							THER’S ONE THING TO TURN IT ROUND ...</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">first song</small>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card h-100  bg-black text-white-50">
					<svg class="bd-placeholder-img card-img-top" width="100%" height="180"
						xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap"
						preserveAspectRatio="xMidYMid slice" focusable="false">
						<title>Placeholder</title>
						<rect width="100%" height="100%" fill="#f58c0c"></rect><text x="50%" y="50%" fill="#dee2e6"
							dy=".3em">Image cap</text>
					</svg>

					<div class="card-body">
						<h5 class="card-title">Here I Am</h5>
						<p class="card-text">SOMETHING INSIDE ME<br />
							TELLS ME GENTLY WHAT I NEED ...
						</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">second song</small>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card h-100  bg-black text-white-50">
					<svg class="bd-placeholder-img card-img-top" width="100%" height="180"
						xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap"
						preserveAspectRatio="xMidYMid slice" focusable="false">
						<title>Placeholder</title>
						<rect width="100%" height="100%" fill="#e30909"></rect><text x="50%" y="50%" fill="#dee2e6"
							dy=".3em">Image cap</text>
					</svg>

					<div class="card-body">
						<h5 class="card-title">Tak bardzo chciał/abym</h5>
						<p class="card-text">GDY SAMOTNOŚCIĄ OTULAM SIĘ<br />
							ZŁE MYŚLI WPADAJĄ W OTWARTE RAMIONA ...
						</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">third song</small>
					</div>
				</div>
			</div>
		</div> -->
		<div class="row row-cols-1 row-cols-md-1 ">
			<!-- <div class="col">
				<div class="tile-song" style="background-color: #FED032;">
					<span>Tak bardzo chciałabym</span>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #FD2F43;">
					<span>Here I Am</span>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #FF6900;">
					<span>One Little Thing</span>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #53D769;">
					<span>One Little Thing</span>
				</div>
			</div>

			<div class="col">
				<div class="d-flex">
					<div class="tile-song-2" style="background-color: black;">
						<span>One </span>
					</div>
					<div class="tile-song-3" style="background-color: #167EFB;">
						<span>Little Thing</span>
					</div>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #975FFE;">
					<span>One Little Thing</span>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #DBED39;">
					<span>One Little Thing</span>
				</div>
			</div>

			<div class="col">
				<div class="tile-song" style="background-color: #F400F5;">
					<span>One Little Thing</span>
				</div>
			</div> -->

			<!-- <div v-for="liryc in lyrics" :key="liryc.title">
				<div class="d-flex">
					<div class="tile-song-2"  style="background-color: black;">
						<span :style="{color: liryc.color }">{{liryc.title_1}} </span>
					</div>
					<div class="tile-song-3"  :style="{backgroundColor: liryc.color }">
						<span >{{liryc.title_2}}</span>
					</div>
				</div>
			</div> -->

			<!-- 	<div v-for="lyric in lyrics" :key="lryc.title">
				<div class="d-flex">
					<div class="tile-song-2"  style="background-color: black;">
						<span :style="{color: lyric.color }">{{liryc.title_1}} </span>
					</div>
					<div class="tile-song-3"  :style="{backgroundColor: liryc.color }">
						<span >{{liryc.title_2}}</span>
					</div>
				</div>
			</div> -->

			<!-- <div v-for="(liryc, index) in lyrics" :key="liryc.title" class="p-2" style="max-width: 500px;"
				:class="{'invert' : index%2===0}">
				<div class="position-relative d-flex w-75 m-auto" style="height: 50px;">
					<div class="position-relative h-100 liryc-tile-left" :style="{'width':	widthReturn(liryc.title)}"></div>
					<div class="position-relative w-50 h-100 liryc-tile-right"></div>

					<div class="position-absolute title-left h-50">
						<span class="m-1 liryc-title-left">
							{{liryc.title_1}}</span>
						<span class="liryc-title-right">{{liryc.title_2}}</span>
					</div>
				</div>

				
			</div> -->

		</div>
	</div>
</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import lyricsData from "../data/lyrics.json";

	export default {
		data() {
			return {
				lyrics: lyricsData
			}
		},
		methods: {
			widthReturn(title) {
				return title.length + '%';
			},
		},
		components: {
			PageHeader
		}
	}
	
</script>

<style>
	/* #lyrics .row>* {
		margin-top: 100px !important;
	} */

	.title-left {
		left: 25% !important;
		top: 25% !important;
	}

	.liryc-tile-left {
		background-color: black;
	}

	.liryc-tile-right {
		background-color: white;
	}

	.invert .liryc-tile-left {
		background-color: white;
	}

	.invert .liryc-tile-right {
		background-color: black;
	}

	.liryc-title-left {
		color: white;
		background-color: black;
	}

	.liryc-title-right {
		color: black;
		background-color: white;
	}

	.invert .liryc-title-left {
		color: black;
		background-color: white;
	}

	.invert .liryc-title-right {
		color: white;
		background-color: black;
	}

	.tile-song-2 {
		height: 50px;
		width: 40%;
		position: relative;
	}

	.tile-song-3 {
		height: 50px;
		width: 40%;
		position: relative;

	}

	.tile-song-2 span {
		position: absolute;
		bottom: 35%;
		right: 5%;
		font-size: 25px;
		font-weight: bold;
	}

	.tile-song-3 span {
		position: absolute;
		bottom: 35%;
		left: 5%;
		color: black;
		font-size: 25px;
		font-weight: bold;
	}

	.tile-song {
		height: 250px;
		width: 250px;
		position: relative;
		margin: auto;
	}

	.tile-song span {
		position: absolute;
		bottom: 35%;
		right: 5%;
		color: black;
		text-align: right;
		font-family: Open Sans;
		font-weight: 500;
		font-size: 25px;
		line-height: 1.8;
		letter-spacing: 1px;
	}
</style>
