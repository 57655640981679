<template>

	<MenuBar></MenuBar>

	<router-view>
	</router-view>

	<footer class="text-white-50 pb-3 p-xl-4 pt-3 bg-black" v-show="showMenubarParam">
		<div class="container mt-xl-2">
			<div class="row">
				<div class="col text-center">
					<a class="social-media" href="https://www.instagram.com/sylviatheresazayn/" target="_blank"> <i
							class="bi bi-instagram" style="color: var(--purple);"></i></a>
					<a class="social-media" href="https://twitter.com/sylvia_T_zayn" target="_blank"> <i
							class="bi bi-twitter-x" style="color: cornflowerblue;"></i></a>
					<a class="social-media"
						href="https://open.spotify.com/user/9cx27z1odd8laweibud59xhzz?si=b0a8ba496bc949c7"
						target="_blank">
						<i class="bi bi-spotify" style="color: mediumseagreen;"></i></a>
					<a class="social-media" href="https://www.tiktok.com/@sylviatheresazayn" target="_blank"> <i
							class="bi bi-tiktok" style="color: var(--lastOrange);"></i></a>
					<a class="social-media" href="https://www.youtube.com/channel/UCAf6Oj86kRVBOJkIHvtOygw"
						target="_blank">
						<i class="bi bi-youtube" style="color: var(--lastRed);"></i></a>
				</div>
			</div>
			<div class="row">
				<div class="col-6 p-1 text-start footer-copyright">© 2021-2024 by Sylvia Theresa Zayn
				</div>
				<div class="col-6 p-1 text-end footer-copyright " v-html="$t('footer.realization')">
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center footer-copyright" v-html="$t('footer.all_right_reserve')">
				</div>
			</div>

		</div>
	</footer>

</template>

<style>
	@import url('https://fonts.googleapis.com/css2?family=Bilbo&family=Freckle+Face&family=Gloria+Hallelujah&family=Just+Me+Again+Down+Here&family=Give+You+Glory&family=Open+Sans&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope');
	@import url('https://fonts.googleapis.com/css2?family=Babylonica&family=Corinthia&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

	@font-face {
		font-family: futura-lt-light;
		src: url(../public/Futura Lt Light.ttf);
	}

	:root {
		--purple: rgb(151, 113, 247);
		--lastOrange: #f58c0c;
		--lastRed: #e30909;
		--lastAbout:var(--purple);
		--lastSongs: #5248ff;
		--lastSoundtracks: #18fc14;
		--lastCooperation: #f58c0c;
		--lastContact: #e30909;
		
	}

	/* 	body {
		background-image: url("../public/background_2022_10_19_c.jpg");
		background-repeat: repeat;
		background-size: cover;
		background-attachment: fixed;
	}
	
	@media only screen and (max-width: 900px) {
		body {
			background-image: url("../public/background_2022_10_19_b.jpg");
			background-repeat: repeat;
			background-size:cover;
			background-attachment: fixed;
		}
	} */

	html {
		margin: 0;
		overscroll-behavior: none;
	}

	.social-media {
		padding: 8px;
	}

	.cover-container {
		max-width: 100%;
	}

	.glory {
		font-family: 'Gloria Hallelujah', cursive;
	}

	.open-sans {
		font-family: 'Open Sans';
	}

	.give-glory {
		font-family: 'Give You Glory';
	}

	.just-me-again-down {
		font-family: 'Just Me Again Down Here', cursive;
	}

	.freckle-face {
		font-family: 'Freckle Face', cursive;
	}

	.bilbo {
		font-family: 'Bilbo', cursive;
	}

	.footer-copyright {
		font-size: 12px;
	}

	@media only screen and (max-width: 900px) {
		.footer-copyright {
			font-size: 10px;
		}
	}

	@media only screen and (max-width: 600px) {
		.main-view {}

	}

	.coming-soon {
		background-image: url(../public/coming_soon_3.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		width: 58em;
		height: 30em;
	}

	@media only screen and (max-width: 1200px) {
		.coming-soon {
			width: 50em;
			height: 21em;
		}
	}

	@media only screen and (max-width: 768px) {
		.coming-soon {
			width: 38em;
			height: 18em;
		}
	}

	@media only screen and (max-width: 576px) {
		.coming-soon {
			width: 22em;
			height: 14em;
		}
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 20px;
		border: 3px solid transparent;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #a8bbbf;
	}
</style>

<script>
	import MenuBar from './components/MenuBar.vue'

	export default {
		name: 'app',
		components: {
			MenuBar
		},
		computed: {
			showMenubarParam() {
				return this.$route.meta.showMenubar;
			}
		}
	};
</script>
