<template>
	<div class="header-h1-test w-100" ref="header_div">
		<h1 class="header-h1" ref="header_h1"
			:style="{'--afterColor': pageColor}"
			v-html="$t('menu.'+pageName)"></h1>
	</div>
</template>

<script>
	export default {
		props: ['pageName', 'pageColor'],
	}
</script>

<style>
	.header-h1 {
		font-family: 'Poppins', sans-serif;
		display: flex;
		position: absolute;
		right: 0;
		color: white;
		margin-right: 20%;
		animation: move 1s ease-out;
	}

	.header-h1:after {
		position: absolute;
		top: calc(50%);
		right: -2vw;
		width: 80vw;
		height: .06em;
		border-radius: 0 .08em .08em 0;
		background-color: var(--afterColor);
		animation: slide 1s ease-out;
		content: '';
		z-index: -100;
	}

	@keyframes move {
		0% {
			margin-right: 0%;
		}

		100% {
			margin-right: 20%;
		}
	}

	@keyframes slide {
		0% {
			transform: translate(-100%)
		}
	}
</style>
