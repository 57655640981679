<template>

	<BackgroundPage backgroundColor="var(--lastAbout)"/>
	<PageHeader pageName="about" pageColor="var(--lastAbout)"></PageHeader>

	<div class="about-main">
		<div class="container about mt-5 " >
			<div class="row p-2 align-items-center">
				<div class="col-12 order-0 order-lg-0 d-block pb-3 pb-lg-0 pt-lg-4">
					<p class="mb-lg-0 " v-html="$t('story.title')"></p>
				</div>
				<div class="col-12 col-lg-6 col-xl-7 order-1 order-lg-1 py-3 py-lg-5">
					<div class="pt-4">
						<p v-html="$t('story.paragraph[0].line[0]')"></p>
						<p v-show="$te('story.paragraph[0].line[1]')" v-html="$t('story.paragraph[0].line[1]')"></p>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-3 py-lg-4 text-end">
					<img src="../../public/about/sylvia_1.jpg" class="w-image" />
				</div>
			</div>

			<div class="row p-2 align-items-center">
				<div class="col-12 col-lg-6 col-xl-7 order-1 order-lg-0 py-3">
					<div class="pt-4 me-lg-5">
						<p v-html="$t('story.paragraph[1].line[0]')"></p>
						<p v-show="$te('story.paragraph[1].line[1]')" v-html="$t('story.paragraph[1].line[1]')"></p>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-1 py-lg-4 text-end">
					<img src="../../public/about/sylvia_2.jpg" class="w-image" />
				</div>
			</div>

			<div class="row p-2 align-items-center">
				<div class="col-12  col-lg-6 col-xl-7 order-1 order-lg-0 py-3">
					<div class="pt-4 me-lg-5">
						<p v-html="$t('story.paragraph[2].line[0]')"></p>
						<p v-show="$te('story.paragraph[2].line[1]')" v-html="$t('story.paragraph[2].line[1]')"></p>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-5  order-2 order-lg-1 py-lg-4 text-end">
					<img src="../../public/about/sylvia_3.jpg" class="w-image" />
				</div>
			</div>

			<div class="row p-2 align-items-center">
				<div class="col-12  col-lg-6 col-xl-7 order-1 order-lg-0 py-3">
					<div class="pt-4 me-lg-5">
						<p v-html="$t('story.paragraph[3].line[0]')"></p>
						<p v-show="$te('story.paragraph[3].line[1]')" v-html="$t('story.paragraph[3].line[1]')"></p>
						<p v-show="$te('story.paragraph[3].line[2]')" v-html="$t('story.paragraph[3].line[2]')" class="w-100"></p>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-1 py-lg-4 text-end">
					<img src="../../public/about/sylvia_4.jpg" class="w-image" />
				</div>
			</div>

			<div class="row p-2 align-items-center">

				<div class="col-12  col-lg-6 col-xl-7 order-1 order-lg-0 py-3">
					<div class="pt-4 me-lg-5">
						<p v-html="$t('story.paragraph[4].line[0]')"></p>
						<p v-show="$te('story.paragraph[4].line[1]')" v-html="$t('story.paragraph[4].line[1]')"></p>
						<p v-html="$t('story.paragraph[5].line[0]')"></p>
						<p v-html="$t('story.paragraph[5].line[1]')"></p>
						<p v-show="$te('story.paragraph[5].line[2]')" v-html="$t('story.paragraph[5].line[2]')"></p>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-1 py-lg-4 text-end">
					<img src="../../public/about/sylvia_studio.jpeg" class="w-image" />
				</div>
			</div>

			<div class="row p-2 align-items-center">
				
				<!-- <div class="col-12 col-lg-5 order-0 order-lg-1 py-4 text-end">

			</div> -->
			</div>
		</div>

	</div>


</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import BackgroundPage from "@/components/BackgroundPage.vue";

	export default {
		components: {
			PageHeader, BackgroundPage
		}
	}
</script>

<style>
	.about-main {}

	.about-bg-color-div {

		background: linear-gradient(160deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, var(--lastAbout) 100%);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100vh;
		position: fixed;
		top: 10;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	@media only screen and (max-width: 576px) {
		.about-bg-color-div {
			
			background-image: linear-gradient(138deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), var(--lastAbout));
		}
	}

	.about p {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.875em;
		color: #FFFFFF;

		animation-name: show-about;
		animation-duration: 2s;
		animation-timing-function: ease-out;

	}

	@keyframes show-about {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.w-image {
		width: 96% !important;
	}

	@media only screen and (max-width: 960px) {
		.w-image {
			width: 100% !important;
		}
	}


	@media only screen and (max-width: 720px) {
		.about p {
			font-size: 14px;
		}
	}
</style>
