import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SongsView from '../views/SongsView.vue'
import LyricsView from '../views/LyricsView.vue'
import SoundtracksView from '../views/SoundtracksView.vue'
import CooperationView from '../views/CooperationView.vue'
import ContactView from '../views/ContactView.vue'
import AboutView from '../views/AboutView.vue'
import i18n from '@/locale/i18n';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:lang?/',
    name: 'home',
    component: HomeView,
	meta: { showMenubar: false },
	children: [
		{
			path: 'about',
			name: 'about1',
			component: AboutView,
			meta: { showMenubar: true, activeColor:'--lastAbout' }
		}
	]
  }, 
  {
    path: '/soundtracks',
    name: 'soundtracks',
    component: SoundtracksView,
	meta: { showMenubar: true, activeColor:'--lastSoundtracks' }
	
  },
  {
    path: '/lyrics',
    name: 'lyrics',
    component: LyricsView,
	meta: { showMenubar: true, activeColor:'--lastLirycs' }
  }, 
  {
    path: '/songs',
    name: 'songs',
    component: SongsView,
	meta: { showMenubar: true, activeColor:'--lastSongs' }
  }, 
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
	meta: { showMenubar: true, activeColor:'--lastContact' }
  }, 
	{
		path: '/cooperation',
		name: 'cooperation',
		component: CooperationView,
		meta: { showMenubar: true, activeColor:'--lastCooperation' }
	},
	{
		path: '/about',
		name: 'about',
		component: AboutView,
		meta: { showMenubar: true, activeColor:'--lastAbout' }
	}

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async to=> {
	if(to.params.lang){
		// i18n.locale = to.params.lang;
	}
	console.log(i18n);
	console.log(to);
})

// router.beforeEach((to, from) => {
//   // ...
//   const a = i18n;
//   const locale = to.params.lang;
//   console.log(a.global);
  
//   return true;
// })

export default router
