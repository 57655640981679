<template>

	<BackgroundPage backgroundColor="var(--lastAbout)" />
	<PageHeader pageName="soundtracks" pageColor="var(--lastAbout)"></PageHeader>

	<main class="container text-center w-100 h-100">

		<div class="row mt-5 ">
			<div class="col">
				<ul class="timeline" :style="{'--dotColor': 'var(--lastAbout)'}">
					<li >
						<h2 class="soundtracks-title-h1 typewriter" :style="{'--delayAnim': '0s'}">Last 38 Minutes</h2>
						<div class="container">
							<div class="row" style="height: 200px;">
								<div class="col-12 col-sm-1"></div>
								<div class="col-12 col-sm-7 m-auto">
								</div>
								<div class="col-12 col-sm-4">
								</div>
							</div>
						</div>
					</li>
					<li>
						<h2 class="soundtracks-title-h1 typewriter" :style="{'--delayAnim': '1s'}">In The Ocean</h2>
						<div class="container">
							<div class="row">
								<div class="col-12 col-sm-1"></div>
								<div class="col-12 col-sm-7 m-auto"></div>
								<div class="col-12 col-sm-4"></div>
							</div>
						</div>
					</li>
				</ul>
			</div>

		</div>

	</main>

</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import BackgroundPage from "@/components/BackgroundPage.vue";

	export default {
		components: {
			PageHeader,
			BackgroundPage
		}
	}
</script>

<style>
	.sountract-bg-color-div {
		background-image: linear-gradient(138deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), var(--lastSoundtracks));
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.soundtracks-title-h1 {
		font-family: 'Poppins', sans-serif;
		text-align: start;
		color: white;
		font-size: 14px;
	}
	
	.soundtracks-title-h1:before  {
		content: ' ';
		background: var(--dotColor);
		display: inline-block;
		position: absolute;
		border-radius: 50%;

		left: 22px;
		width: 15px;
		height: 15px;
		z-index: 400;
	}
	
</style>
