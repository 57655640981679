<template>
	<BackgroundPage backgroundColor="var(--lastAbout)" />
	<PageHeader pageName="songs" pageColor="var(--lastAbout)"></PageHeader>

	<main class="container text-center w-100">

		<div class="row mt-5 ">
			<div class="col">
				<ul class="timeline" :style="{'--dotColor': 'var(--lastAbout)'}">
					<li>
						<h2 class="typewriter song-title-h1" :style="{'--delayAnim': '0s'}">Here I Am</h2>
						<span class="song-title-dot" />
						<h3 class="typewriter song-title-span" :style="{'--delayAnim': '1s'}">Jazz Waltz</h3>
						<div class="container">
							<div class="row">

								<div class="col-12 col-sm-1"></div>
								<div class="col-12 col-sm-7 m-auto ">
									<!-- <div class="ratio ratio-16x9">
										<iframe src="https://www.youtube.com/embed/e6dJ_wNZCd0"
											title="YouTube video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen></iframe>
									</div> -->

								</div>
								<div class="col-12 col-sm-4 song-lyrics p-4 p-sm-0">
									<div class="song-lyrics-div">

										<div>
											<div v-html="$t('songs.lyrics')"></div>
											<div>Sylvia Theresa Zayn</div>
										</div>
									</div>
									<div class="p-3 song-lyrics-text">
										Somethin' inside me
										<br />
										gently tells me what I need.
										<br />
										Now, here I am. Look at me.
										<br />
										Day by day, I fight by myself.
										<br /><br />
										Look into my heart. I’m stronger now.
										<br />
										My wounds are healin'.
										<br />
										Look into my eyes, what do you see?
										<br />
										It is really me.
										<br /><br />
										Somethin' inside me.
										<br />
										broke and set me free.
										<br />
										Now, here I am. Look at me.
										<br />
										Day by day, I look for a better way.
										<br /><br />
										Look at my face. I’m so happy now.
										<br />
										The past is behind me.
										<br />
										Look into my soul, what can you see?
										<br />
										I’m really free!
										<br /><br />
										Oh, here I am!
										<br />
										Stronger than ever before!
										<br />
										Look, what do you see?
										<br />
										I’m really free!
										<br />
										Hey! Here I am!

									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<h2 class="typewriter song-title-h1" :style="{'--delayAnim': '2s'}">One Little Thing</h2>
						<span class="song-title-dot" />
						<h3 class="typewriter song-title-span" :style="{'--delayAnim': '3s'}">Pop / Rock</h3>
						<div class="container">
							<div class="row">

								<div class="col-12 col-sm-1"></div>
								<div class="col-12 col-sm-7 m-auto ">
									<!-- <div class="ratio ratio-16x9">
										<iframe src="https://www.youtube.com/embed/e6dJ_wNZCd0"
											title="YouTube video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen></iframe>
									</div>
 -->
								</div>
								<div class="col-12 col-sm-4 song-lyrics p-4 p-sm-0">
									<div class="song-lyrics-div">

										<div>
											<div v-html="$t('songs.lyrics')"></div>
											<div> Sylvia Theresa Zayn</div>
										</div>
									</div>
									<div class="">
										<div class="p-3 song-lyrics-text  ">
											When life just seems
											<br />
											to bring you down,
											<br />
											there’s one thing that turns it round
											<br />
											to make your day.
											<br />
											Your mind goes to a better time.
											<br />
											Turns that frown upside down.
											<br />
											You’re set to go.
											<br />
											One little thing,
											<br />
											a little like:
											<br /><br />
											One step, one way,
											<br />
											one smile, one day,
											<br />
											two hearts in a band,
											<br />
											my hand in your hand.
											<br />
											Your face, your lips,
											<br />
											one place, one kiss,
											<br />
											the time when I see
											<br />
											that you believe in me.
											<br /><br />
											When days appear
											<br />
											so dark and blue,
											<br />
											your friends long gone, they left you.
											<br />
											You’re all alone.
											<br />
											When there’s no comfort anywhere.
											<br />
											You live your life day by day,
											<br />
											just waiting for
											<br />
											one little thing,
											<br />
											a little like:
											<br /><br />
											One step, one way,
											<br />
											one smile, one day,
											<br />
											two hearts in a band,
											<br />
											my hand in your hand.
											<br />
											Your face, your lips,
											<br />
											one place, one kiss,
											<br />
											the time when I see
											<br />
											that you believe in me.
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>

		</div>

	</main>

</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import BackgroundPage from "@/components/BackgroundPage.vue";

	export default {
		components: {
			PageHeader,
			BackgroundPage
		}
	}
</script>

<style>
	.song-title-h1 {
		font-family: 'Poppins', sans-serif;
		text-align: start;
		color: white;
		font-size: 14px;

	}

	.song-title-span {
		font-family: 'Poppins', sans-serif;
		text-align: start;
		color: gray;
		font-size: 12px;

	}

	.song-lyrics {
		color: white;
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		text-align: start;
		background-color: transparent;
		border-radius: 4%;

	}


	.song-lyrics-div {
		border-bottom: 0.5px solid gray;
		font-size: 17px;
		padding-bottom: 1em;
	}

	.song-lyrics-div div {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.song-lyrics-div div div {
		font-size: 14px;
		color: gray;
	}

	.song-lyics p {
		font-size: 16px;
	}


	.typewriter {
		overflow: hidden;
		/* The typwriter cursor */
		white-space: nowrap;
		/* Keeps the content on a single line */
		margin: 0;
		/* Gives that scrolling effect as the typing happens */
		/* letter-spacing: .15em; */
		/* Adjust as needed */
		animation: typing 5s ease-in-out var(--delayAnim) normal both;

	}

	/* The typing effect */
	@keyframes typing {
		from {
			width: 0
		}

		to {
			width: 100%
		}
	}

	/* The typewriter cursor effect */
	@keyframes blink-caret {

		from,
		to {
			border-color: transparent
		}

		50% {
			border-color: orange;
		}
	}

	ul.timeline {
		list-style-type: none;
		position: relative;
	}

	ul.timeline:before {
		content: ' ';
		background: gray;
		display: inline-block;
		position: absolute;
		top: 14px;
		left: 29px;
		width: 0.5px;
		height: 100%;
		z-index: 400;
	}

	ul.timeline>li {
		margin: 20px 0 4em 0;
		padding-left: 20px;
	}

	ul.timeline>li .song-title-dot:before {
		content: ' ';
		background: var(--dotColor);
		display: inline-block;
		position: absolute;
		border-radius: 50%;
		margin-top: -8px;
		left: 22px;
		width: 15px;
		height: 15px;
		z-index: 400;
	}

	.song-lyrics-text {
		overflow: auto;
		overscroll-behavior-y: contain;
		max-height: 300px;
	}

	.scrollbox {
		overflow: auto;
		max-height: 200px;


		background:
			/* Shadow covers */
			linear-gradient(white 30%, rgba(0, 0, 0, 0)),
			linear-gradient(rgba(0, 0, 0, 0), white 70%) 0 100%,

			/* Shadows */
			radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)),
			radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)) 0 100%;
		background:
			/* Shadow covers */
			linear-gradient(transparent 30%, rgba(0, 0, 0, 0)),
			linear-gradient(rgba(0, 0, 0, 0), transparent 70%) 0 100%,
			
			/* Shadows */
			radial-gradient(farthest-side at 50% 0, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0)),
			radial-gradient(farthest-side at 50% 100%, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0)) 0 100%;
		background-repeat: no-repeat;
		background-color: transparent;
		background-size: 100% 50px, 100% 50px, 100% 14px, 100% 14px;

		/* Opera doesn't support this in the shorthand */
		background-attachment: local, local, scroll, scroll;
	}
</style>
