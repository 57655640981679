<template>
	<div class="home-bg-color-div w-100"></div>
	<div class="position-absolute top-0 end-0 p-3">
		<button v-for="entry in languages" :key="entry.title" v-show="entry.language != this.$i18n.locale"
			@click="changeLocale(entry.language)" class="language-button">
			<span class="language-button">{{entry.code}}</span>
		</button>
	</div>
	<div class="h-100 w-100 m-auto d-flex">
		<main class="container m-auto home-container">
			<div class="text-center">
				<p class="home-welcome" v-html="$t('home.content')">
				</p>
				<p class="home-signature"></p>
				<div class="home-enter-div">
					<router-link class="home-enter" to="/about">Enter</router-link>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import i18n from '@/locale/i18n';

	export default {
		data() {
			return {
				languages: [{
						code: 'EN',
						language: 'en',
						title: 'English'
					},
					{
						code: 'PL',
						language: 'pl',
						title: 'Polski'
					}
				]
			};
		},
		methods: {
			changeLocale(locale) {
				this.$i18n.locale = locale;
			}
		}
	};
</script>

<style>
	.language-button {
		background-color: transparent;
		border: none;
	}

	.language-button span {
		color: darkgray;
		font-size: 1.2rem;
		font-family: 'Poppins', sans-serif;
	}

	.home-bg-color {
		background-color: black !important;
		background: linear-gradient(171deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(151, 113, 247, 1) 100%);
		background-repeat: repeat;
		background-size: cover;
		background-attachment: fixed;
		z-index: 1;
	}

	.home-bg-color-div {

		background: linear-gradient(156deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(151, 113, 247, 1) 100%);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	@media only screen and (max-width: 576px) {
		.home-bg-color-div {
			
			background-image: linear-gradient(138deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),  rgba(151, 113, 247, 1) );
		}
	}

	.home-container {
		animation-name: show-home;
		animation-duration: 2s;
		animation-timing-function: ease-out;
	}

	@keyframes show-home {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.home-logo {
		margin-top: 5%;
		margin-left: 18%;
		height: 0;
	}

	.home-welcome span {
		display: block;
	}

	.home-welcome {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.875em;
		color: #FFFFFF;
		width: 100%;
		margin-bottom: -2%;
		margin-right: 3%;
	}

	.home-enter {
		font-family: Give You Glory;
		font-weight: 400;
		font-size: 26px;
		color: #FFFFFF;
		text-decoration: none;
	}

	.home-enter:hover {
		color: dimgray !important;
	}

	.home-signature {
		background-image: url(../../public/logo_sylvia_krzywa_1_white.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		width: 15em;
		height: 8em;
		margin-left: 28%;

	}

	@media only screen and (min-width: 600px) {
		.home-signature {
			margin-left: 50%;
			width: 18em;
			height: 8em;
		}

	}

	@media only screen and (max-width: 992px) {

		.home-enter-div {
			margin-top: 20%;
		}


	}

	@media only screen and (max-width: 1200px) {
		.home-welcome {
			font-size: 16px;
		}


	}

	@media only screen and (min-width: 1200px) {

		.home-signature {
			margin-top: 2%;
		}

		.home-enter {
			font-size: 30px;
		}

		.home-enter-div {
			margin-top: 10%;
		}
	}

	@media only screen and (max-width: 992px) {

		.home-enter {
			font-size: 22px;
		}



	}
</style>
